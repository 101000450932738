import { graphql } from "gatsby"
import { OurProcess } from "../OurProcess/OurProcess"

export const query = graphql`
  query OurProcessQuery {
    ourProcess: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/process.md/" }
    ) {
      frontmatter {
        title
        subtitle
        philosophy {
          title
          description
          buttonText
        }
        process {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(height: 70)
            }
          }
          examples {
            example
          }
        }
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }

    happyClients: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/happyClients.md/" }
    ) {
      frontmatter {
        title
        subtitle
        happyClients
      }
    }
    allCaseStudies: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/case_studies/" } }
    ) {
      nodes {
        frontmatter {
          client
          logo {
            childImageSharp {
              gatsbyImageData(height: 40)
            }
          }
        }
      }
    }
    contactCard: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/contactCard.md/" }
    ) {
      frontmatter {
        title
        subtitle
        buttonText
        contactEmail
      }
    }
  }
`
export default OurProcess
