import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"

export interface ClientCaseStudyGraphQLData {
  nodes: Array<{
    frontmatter: {
      client: string
      logo: ImageDataLike
    }
  }>
}

export interface HappyClientsGraphQLData {
  frontmatter: {
    title: string
    subtitle: string
    happyClients: Array<string>
  }
}

export const getHappyClients = (
  clients: string[],
  caseStudies: ClientCaseStudyGraphQLData
) => {
  const filteredCaseStudies = caseStudies.nodes
    .filter(({ frontmatter }) => clients.includes(frontmatter.client))
    .map(({ frontmatter }) => frontmatter)

  if (filteredCaseStudies.length < clients.length) {
    throw new Error(`Not all clients were found in the case studies.`)
  }

  return filteredCaseStudies.map((client) => {
    const logo = process.env.STORYBOOK
      ? (client.logo as IGatsbyImageData)
      : getImage(client.logo)

    if (!logo) {
      throw new Error(`Could not find image for client: ${client.client}`)
    }
    return { name: client.client, logo }
  })
}
