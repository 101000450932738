import React from "react"
import styled from "@emotion/styled"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { AccentDivider } from "../AccentDivider/AccentDivider"
import { Paper } from "../Paper/Paper"
import { Typography } from "../Typography/Typography"
import { breakpoint } from "../../theme/theme"

interface ProcessStepProps {
  stepCount: number
  title: string
  description: string
  image: IGatsbyImageData
  examples: Array<{ example: string }>
}

export const ProcessStep: React.FC<ProcessStepProps> = ({
  stepCount,
  title,
  description,
  image,
  examples,
}) => {
  return (
    <Container>
      <div>
        <ImageContainer color="blue" gradient>
          <GatsbyImage image={image} alt={title} />
        </ImageContainer>
      </div>
      <PrimaryContainer>
        <SecondaryContainer>
          <HeadingContainer>
            <StepText variant="caption">STEP {stepCount}</StepText>
            <AccentDivider />
          </HeadingContainer>
          <TextContainer>
            <Typography variant="h3">{title}</Typography>
            <ReactMarkdown
              components={{
                p: ({ node: _node, ...props }) => (
                  <Text variant="p" {...props} />
                ),
              }}
            >
              {description}
            </ReactMarkdown>
          </TextContainer>
        </SecondaryContainer>
        <CategoriesContainer>
          {examples.map((example) => (
            <Text variant="p" key={example.example}>
              {example.example}
            </Text>
          ))}
        </CategoriesContainer>
      </PrimaryContainer>
    </Container>
  )
}

const Container = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr",
  flexDirection: "column",
  gap: "3em",
  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 2fr",
  },
})

const PrimaryContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "3em",
  [breakpoint("sm")]: {
    gridTemplateColumns: "2fr 1fr",
  },
})

const ImageContainer = styled(Paper)({
  padding: "25px 40px",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "24px",
  [breakpoint("md")]: {
    padding: "50px 80px",
  },
})

const SecondaryContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
})

const HeadingContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "1em",
})
const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  color: theme.palette.darkblue.main,
}))

const StepText = styled(Typography)({
  fontWeight: 800,
})

const Text = styled(Typography)({
  lineHeight: "24px",
  color: "#000000",
})

const CategoriesContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  justifyContent: "center",
  paddingLeft: "2em",
  [breakpoint("sm")]: {
    paddingLeft: "0",
  },
})
