import styled from "@emotion/styled"
import { PageProps } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"
import React from "react"
import ReactMarkdown from "react-markdown"
import { ContactCard } from "../components/ContactCard/ContactCard"
import { HappyClients } from "../components/HappyClients/HappyClients"
import {
  ClientCaseStudyGraphQLData,
  getHappyClients,
  HappyClientsGraphQLData,
} from "../components/HappyClients/happyClients.utils"
import { BasePageData, withLayout } from "../components/Layout/layout.hocs"
import { breakpoint } from "../theme/theme"
import { Paper } from "../components/Paper/Paper"
import { PrimaryButton } from "../components/PrimaryButton/PrimaryButton"
import { ProcessStep } from "../components/ProcessStep/ProcessStep"
import { Typography } from "../components/Typography/Typography"
import { getProcessStepswithImages } from "./ourProcess.utils"
import { PlainLink } from "../components/PlainLink/PlainLink"
import { AccentDivider } from "../components/AccentDivider/AccentDivider"

export interface Philosophy {
  title: string
  description: string
  buttonText: string
}

export interface ProcessStep {
  title: string
  description: string
  examples: Array<{
    example: string
  }>
  image: ImageDataLike
}

interface ProvidedServicesData extends BasePageData {
  ourProcess: {
    frontmatter: {
      title: string
      subtitle: string
      philosophy: Philosophy
      process: Array<ProcessStep>
    }
  }
  happyClients: HappyClientsGraphQLData
  allCaseStudies: ClientCaseStudyGraphQLData
  contactCard: {
    frontmatter: {
      title: string
      subtitle: string
      buttonText: string
      contactEmail: string
    }
  }
}

export type OurProcessProps = PageProps<ProvidedServicesData>

export const OurProcess: React.FC<OurProcessProps> = withLayout(
  { title: "Our Process", variant: "light" },
  (props) => {
    const clients = getHappyClients(
      props.data.happyClients.frontmatter.happyClients,
      props.data.allCaseStudies
    )
    const happyClients = props.data.happyClients.frontmatter
    const philosophy = props.data.ourProcess.frontmatter.philosophy
    const processSteps = getProcessStepswithImages(
      props.data.ourProcess.frontmatter.process
    )
    const title = props.data.ourProcess.frontmatter.title
    const subtitle = props.data.ourProcess.frontmatter.subtitle
    const contactCardProps = props.data.contactCard.frontmatter

    return (
      <>
        <Paper gradient color="blue">
          <TitleSection>
            <PageTitle variant="h1">{title}</PageTitle>
            <AccentDivider />
            <Typography variant="p">{subtitle}</Typography>
          </TitleSection>
        </Paper>
        <Paper color="darkblue">
          <PhilosophySection>
            <PhilosophyContent>
              <Typography variant="h2">{philosophy.title}</Typography>
              <ReactMarkdown
                components={{
                  p: ({ node: _node, ...props }) => (
                    <PhilosphyText variant="p" {...props} />
                  ),
                }}
              >
                {philosophy.description}
              </ReactMarkdown>
              <PlainLink to="/contact">
                <PrimaryButton>{philosophy.buttonText}</PrimaryButton>
              </PlainLink>
            </PhilosophyContent>
          </PhilosophySection>
        </Paper>
        <Container>
          <ProcessStepsContainer>
            {processSteps.map((process, index) => (
              <ProcessStep
                {...process}
                image={process.image}
                stepCount={index + 1}
                key={process.title}
              />
            ))}
          </ProcessStepsContainer>
          <HappyClients
            title={happyClients.title}
            subtitle={happyClients.subtitle}
            clients={clients}
          />

          <ContactCard {...contactCardProps} />
        </Container>
      </>
    )
  }
)

const MaxWidthContainer = styled("div")({
  margin: "0 auto",
  width: "100%",
  [breakpoint("lg")]: {
    maxWidth: "1200px",
  },
})

const TitleSection = styled(MaxWidthContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  padding: "8em 2em 4em",
  [breakpoint("lg")]: {
    padding: "8em 8em 4em",
  },
})
const PhilosophySection = styled(MaxWidthContainer)({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "3em",
  padding: "4em 2em",

  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
})

const Container = styled(MaxWidthContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "3em",
  padding: "5em 2em",
  width: "auto",
})

const PhilosophyContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
})

const ProcessStepsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})

const PhilosphyText = styled(Typography)({
  lineHeight: "24px",
})

const PageTitle = styled(Typography)({
  fontSize: "32px",
  [breakpoint("sm")]: {
    fontSize: "64px",
  },
})
