import { getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { ProcessStep } from "./OurProcess"

export const getProcessStepswithImages = (processSteps: Array<ProcessStep>) => {
  return processSteps.map((step) => {
    const stepImage = process.env.STORYBOOK
      ? (step.image as IGatsbyImageData)
      : getImage(step.image)
    if (!stepImage) {
      throw new Error(`Could not find image for ${step.title}`)
    }

    return {
      ...step,
      image: stepImage,
    }
  })
}
