import styled from "@emotion/styled"
import React from "react"
import { breakpoint } from "../../theme/theme"
import { Paper } from "../Paper/Paper"
import { PlainLink } from "../PlainLink/PlainLink"
import { PrimaryButton } from "../PrimaryButton/PrimaryButton"
import { Typography } from "../Typography/Typography"

interface ContactCardProps {
  title: string
  subtitle: string
  buttonText: string
  contactEmail: string
}

export const ContactCard: React.FC<ContactCardProps> = ({
  title,
  subtitle,
  buttonText,
  contactEmail,
}) => {
  return (
    <Card color="blue" gradient>
      <Container>
        <TextContainer>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="p">{subtitle}</Typography>
        </TextContainer>
        <PlainLink to="/contact">
          <PrimaryButton>{buttonText}</PrimaryButton>
        </PlainLink>
      </Container>
      <FlexContainer>
        <EmailContainer>
          <EmailText variant="p">{contactEmail}</EmailText>
        </EmailContainer>
      </FlexContainer>
    </Card>
  )
}

const Card = styled(Paper)({
  display: "grid",
  gridTemplateColumns: "1fr",
  borderRadius: "8px",
  padding: "40px",
  gap: "2em",
  [breakpoint("sm")]: {
    gridTemplateColumns: "1fr 1fr",
  },
})
const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5em",
})

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1.5em",
})

const EmailContainer = styled("div")({
  display: "flex",
  height: "100%",
  gap: "0.5em",
  alignItems: "center",
})

const EmailText = styled(Typography)({
  fontWeight: 700,
})

const FlexContainer = styled("div")({
  display: "flex",
  alignSelf: "end",
  [breakpoint("sm")]: {
    justifyContent: "flex-end",
  },
})
