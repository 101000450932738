import styled from "@emotion/styled"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { breakpoint } from "../../theme/theme"
import { AccentDivider } from "../AccentDivider/AccentDivider"
import { Typography } from "../Typography/Typography"

interface HappyClientsProps {
  title: string
  subtitle: string
  clients: Array<{ name: string; logo: IGatsbyImageData }>
}
export const HappyClients: React.FC<HappyClientsProps> = ({
  title,
  subtitle,
  clients,
}) => {
  return (
    <Container>
      <AccentDivider />
      <TextContainer>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="p">{subtitle}</Typography>
      </TextContainer>
      <ClientsContainer columns={clients.length}>
        {clients.map(({ name, logo }) => (
          <LogoContainer key={name}>
            <GatsbyImage image={logo} alt={name} />
          </LogoContainer>
        ))}
      </ClientsContainer>
    </Container>
  )
}

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "3em",
  alignItems: "center",
})

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  color: theme.palette.darkblue.main,
  alignItems: "center",
}))

const ClientsContainer = styled("div")<{ columns: number }>(({ columns }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "1em",
  [breakpoint("sm")]: {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  },
}))

const LogoContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
})
